<template>
  <section>
    <title-section
      title="Ventas"
    >
      <div class="level-item">
        <button
          class="button button_micro_dark"
          @click="csvDownload()"
          >
          EXPORTAR
        </button>
      </div>
    </title-section>
    <main-sales
      ref="salesMainRef"
    />
  </section>
</template>

<script>
export default {
  name: 'Sales',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    MainSales: () => import('@/components/Sales/MainSales.vue')
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async csvDownload () {
      this.loading = true
      await this.$refs.salesMainRef.csvDownloadSalesAction()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
    width: 200px;
  }
</style>
